<template>
  <VisitorWrapper>
    <CContainer>
      <CRow
        v-if="!isSchoolSettingsLoading"
        class="justify-content-center mt-4 bg-white rounded-30 p-4 d-flex flex-column gap-2"
      >
        <!-- SERVER ERROR -->
        <VisitorErrorHandler
          v-if="serverError != null"
          :error="serverError"
          @done="serverError = null"
        />
        <!-- SERVER ERROR -->

        <VisitorMatrixSetting />
      </CRow>
      <CRow
        v-else
        class="d-flex justify-content-center align-content-center mt-4 bg-white rounded-30 p-4 gap-2"
      >
        <CSpinner color="primary" />
      </CRow>
      <div
        class="p-4 gap-2 text-accent-blue-light cursor-pointer"
        style="width: max-content"
        @click="previousUploads()"
      >
        Previous Uploads
      </div>
    </CContainer>
  </VisitorWrapper>
</template>
<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import VisitorWrapper from "@/v3components/VisitorWrapper.vue"
import VisitorMatrixSetting from "@/v3components/Forms/Visitor/GuardianStudentMatrix/VisitorMatrixSetting.vue"
import { onMounted, defineAsyncComponent, inject, ref } from "vue"
import { useStore } from "vuex"
const VisitorGuardianStudentLogFiles = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Datatables/Visitor/ListSettings/VisitorGuardianStudentLogFiles.vue"
    )
)

export default {
  name: "VisitorGuardianStudentMatrix",
  components: {
    VisitorMatrixSetting,
    VisitorWrapper,
    VisitorErrorHandler
  },
  setup() {
    const modal = inject("modal")
    const store = useStore()
    const serverError = ref(null)
    const isSchoolSettingsLoading = ref(true)

    onMounted(async () => {
      try {
        isSchoolSettingsLoading.value = true
        await store.dispatch("visitorSettings/getSchoolSettings")
      } catch (error) {
        serverError.value = error
      } finally {
        isSchoolSettingsLoading.value = false
      }
    })

    const previousUploads = () => {
      modal.open(VisitorGuardianStudentLogFiles, {
        hideCloseButton: true,
        hideHeader: true,
        size: "lg",
        props: {
          events: { closeModal: onCloseModal }
        }
      })
    }

    const onCloseModal = () => {
      modal.close()
    }

    return {
      isSchoolSettingsLoading,
      serverError,
      previousUploads
    }
  }
}
</script>

<style scoped>
.pt-2\.5 {
  padding-top: 0.72rem;
}
</style>
